<template>
  <b-button :disabled="disabled" type="submit" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="success">
    <feather-icon icon="CheckIcon" class="mr-50" />
    <span class="align-middle">Guardar</span>
  </b-button>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
    components: {
        BButton,
    },
    directives: {
        Ripple,
    },
    props: {
      disabled: {
        type: Boolean,
        default: false
      }
    }
}
</script>

<style scoped>

</style>
