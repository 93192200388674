<template>
  <b-form-checkbox v-model="model" class="custom-control-success" name="check-button" switch>
    <span class="switch-icon-left">
      <feather-icon icon="CheckIcon" />
    </span>
    <span class="switch-icon-right">
      <feather-icon icon="XIcon" />
    </span>
  </b-form-checkbox>
</template>

<script>
import { BFormCheckbox } from 'bootstrap-vue'

export default {
  components: { BFormCheckbox },
  props: {
    value: {
      required: true,
    },
  },
  data() {
    return {
      model: this.value,
    }
  },
  watch: {
    model(newValue) {
      this.$emit('input', newValue)
      this.$emit('onChange', newValue)
    },
    value(newValue) {
      this.model = newValue
    },
  },
}
</script>

<style scoped>

</style>
